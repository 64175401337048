<template>
  <div id="container">
    <div id="mapContainer"></div>
  </div>
</template>

<script>
  import L from 'leaflet';
  import moment from 'moment';
  import 'leaflet/dist/leaflet.css';

  import worldMap from '@/common/custom.geo.json';
  import API_COUNTRY from '@/common/utils';

  export default {
    name: 'EventMapComponent',
    data() {
      return {
        center: [40, 0],
        world: worldMap,
        countries: [],
        eventsInner: [],
        mapDiv: null,
        geoJsonLayer: null,
      };
    },
    props: {
      events: {
        type: Object,
        default: () => {},
      },
    },
    watch: {
      events: function (newValue) {
        if (newValue && newValue.items) this.eventsInner = newValue.items;
        else this.eventsInner = [];
        this.restyleGeoJsonLayer();
      },
    },
    created() {},
    methods: {
      loadCountries() {
        API_COUNTRY.searchNsi('countryListWithData', true).then((response) => {
          this.countries = response.data;
          this.setupLeafletMap();
        });
      },

      styleMap(feature) {
        let color = '#E3E3E3';
        let code = Number(feature.properties.iso_n3).toString();
        let country = this.countries.find((x) => x.code == code);
        if (country) {
          feature.properties.rusName = country.name;
        }

        let eventCount = this.eventsInner.filter((x) => x.country.code == code).length;
        feature.properties.countryStatus = eventCount;

        if (eventCount > 0) {
          color = '#309CFF';
        }
        if (code == 304) {
          return {
            color: '#60a7ee',
            fillColor: 'white',
            weight: 1,
            opacity: 0.5,
          };
        }
        return { color: '#60a7ee', fillColor: color, weight: 1, opacity: 0.7 };
      },
      onEachFeature(feature, layer) {
        if (feature.properties && feature.properties.rusName) {
          layer.on('mouseup', () => {
            layer.unbindPopup();
            var code = Number(feature.properties.iso_n3).toString();
            var country = this.countries.find((x) => x.code == code);

            if (country) {
              let curDate = new Date();
              let eventList = ``;
              let events = this.eventsInner.filter((x) => x.country.code == code);
              let eventCount = events.length;
              eventList = `<strong style="color:  #0078A8; margin: 0 0 12px 0; display: block">Активных мероприятий: ${eventCount}</strong><ul>`;
              if (eventCount > 0) {
                events.forEach((e) => {
                  var str = `<li style="list-style-type: none; padding: 6px 0 !important; margin: 0 !important;">
                              <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                                <div>
                                  <p style="margin: 0 12px;">
                                    <strong>${e.eventType.name}</strong>
                                    <span> - </span>
                                    <span>${moment(e.eventDatesList.filter((x) => new Date(x) >= curDate)[0]).format(
                                      'DD.MM.YYYY',
                                    )}</span>
                                  </p>
                                  <p style="margin: 0 12px;">
                                    <span>Регистрация ${e.isRegistrationOpen ? 'открыта' : 'закрыта'}</span>
                                  </p>
                                  <p style="margin: 0 12px;">
                                    <strong>${e.name}</strong>
                                  </p>                              
                                </div>     
                                <div class="my-3">                           
                                  <a class="btn-primary" style="color: white;" href="/#/mpk/events/edit/${
                                    e.id
                                  }" target="_self">Просмотр</a>
                                </div>
                              </div>                              
                            </li>`;
                  eventList = eventList.concat(str);
                });
              }

              layer.bindPopup(
                `<div class="popup">
                      <div class="popup__head d-flex align-items-center justify-content-between div-country-clickable-header">
                        <p class="font-weight-bold">${country.nameFull}</p>
                      </div>
                      <div class="popup__body p-3">
                      ${eventList}
                      </ul>
                </div>`,
                eventList,
                feature.properties.countryStatus.toString(),
              );

              layer.openPopup();
            }
          });
        }
      },
      setupLeafletMap: function () {
        let mapContainer = window.document.getElementById('mapContainer');
        while (mapContainer.firstChild) {
          mapContainer.removeChild(mapContainer.firstChild);
        }
        if (this.mapDiv) {
          this.mapDiv.remove();
          this.mapDiv = null;
        }
        this.mapDiv = L.map('mapContainer').setView(this.center, 1.4);
        L.tileLayer('', {
          maxZoom: 5,
          minZoom: 1.4,
        }).addTo(this.mapDiv);
        this.geoJsonLayer = L.geoJSON(this.world, {
          weight: 0.5,
          fillOpacity: 1,
        }).addTo(this.mapDiv);
        this.restyleGeoJsonLayer();
      },
      restyleGeoJsonLayer() {
        if (this.geoJsonLayer) {
          this.geoJsonLayer.eachLayer(this.restyleLayer);
        }
      },
      restyleLayer(layer) {
        let style = this.styleMap(layer.feature);
        layer.setStyle(style);
        this.onEachFeature(layer.feature, layer);
      },
    },
    mounted() {
      this.loadCountries();
    },
  };
</script>

<style scoped lang="scss">
  #mapContainer {
    background-color: #ffffff;
    width: 100%;
    height: 500px;
    overflow: hidden;
  }
</style>
