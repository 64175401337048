<template>
  <div class="v-redes-calendar" :class="{ [`v-redes-calendar--mode_${mode}`]: mode }">
    <div class="v-redes-calendar__wrapper">
      <button class="v-redes-calendar__arrow" @click="prev">
        <svg width="17" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.7915 7.00655H3.62148L8.50148 2.12655C8.89148 1.73655 8.89148 1.09655 8.50148 0.706548C8.31465 0.519295 8.061 0.414062 7.79648 0.414062C7.53197 0.414062 7.27832 0.519295 7.09148 0.706548L0.501484 7.29655C0.111484 7.68655 0.111484 8.31655 0.501484 8.70655L7.09148 15.2965C7.48148 15.6865 8.11148 15.6865 8.50148 15.2965C8.89148 14.9065 8.89148 14.2765 8.50148 13.8865L3.62148 9.00655H14.7915C15.3415 9.00655 15.7915 8.55655 15.7915 8.00655C15.7915 7.45655 15.3415 7.00655 14.7915 7.00655Z"
            fill="#fff"
          />
        </svg>
      </button>

      <v-calendar
        ref="calendar"
        :columns="parseInt(columns)"
        :rows="parseInt(rows)"
        :step="parseInt(step)"
        :attributes="attributes"
        :locale="{ id: 'ru', firstDayOfWeek: 2, masks: { weekdays: 'WW' } }"
        from-page="1"
        @update:from-page="loadCalendar"
        @update:pages="(...args) => $emit('update:pages', ...args)"
        @did-move="(...args) => $emit('did-move', ...args)"
      >
        <template #day-popover="popoverScope">
          <button class="v-redes-calendar__close-popover" @click="popoverScope.hide()">
            <IconComponent name="clear_cross" />
          </button>
          <PerfectScrollbar>
            <template v-if="$slots.customPopover">
              <div class="day-popover">
                <div class="day-popover__item-body">
                  <slot name="customPopover" v-bind="popoverScope"></slot>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="day-popover"
                v-for="{ key, customData } in popoverScope.attributes"
                :key="key"
                :ref="`popover${key}`"
              >
                <div class="day-popover__item-body">
                  <!-- Тип мероприятия -->
                  <p class="type">{{ customData?.eventType?.name }}</p>
                  <!-- Дата мероприятия -->
                  <p class="date">{{ popoverScope.dayTitle }}</p>
                  <!-- Проверка наличия времени -->
                  <p class="time-container">
                    <span class="time-block">
                      <span v-if="customData.time != ''" class="time">{{ customData.time }}</span>
                      <span>
                        <span>Регистрация</span>
                        <span v-if="customData.isRegistrationOpen"> открыта</span>
                        <span v-else> закрыта</span>
                      </span>
                    </span>
                  </p>
                  <!-- Текст мероприятия -->
                  <p class="about">{{ customData.name }}</p>
                  <!-- Просмотр мероприятия -->
                  <ButtonStock title="Просмотр" @click="editCard(customData.id || 0)" />
                </div>
              </div>
            </template>
          </PerfectScrollbar>
        </template>
      </v-calendar>

      <button class="v-redes-calendar__arrow next" @click="next">
        <svg width="17" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.7915 7.00655H3.62148L8.50148 2.12655C8.89148 1.73655 8.89148 1.09655 8.50148 0.706548C8.31465 0.519295 8.061 0.414062 7.79648 0.414062C7.53197 0.414062 7.27832 0.519295 7.09148 0.706548L0.501484 7.29655C0.111484 7.68655 0.111484 8.31655 0.501484 8.70655L7.09148 15.2965C7.48148 15.6865 8.11148 15.6865 8.50148 15.2965C8.89148 14.9065 8.89148 14.2765 8.50148 13.8865L3.62148 9.00655H14.7915C15.3415 9.00655 15.7915 8.55655 15.7915 8.00655C15.7915 7.45655 15.3415 7.00655 14.7915 7.00655Z"
            fill="#fff"
          />
        </svg>
      </button>
    </div>

    <div v-if="$slots.legend" class="v-redes-calendar__legend">
      <slot name="legend"></slot>
    </div>
  </div>
</template>

<script>
  import { PerfectScrollbar } from 'vue3-perfect-scrollbar';

  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  export default {
    name: 'Calendar',
    components: { PerfectScrollbar, IconComponent, ButtonStock },
    props: {
      columns: {
        type: [String, Number],
        default: '3',
      },
      rows: {
        type: [String, Number],
        default: 1,
      },
      step: {
        type: [String, Number],
        default: '1',
      },
      attributes: {
        type: Array,
        default: () => [],
      },
      mode: {
        type: [String, null],
        validator(value) {
          return value === null || ['single'].includes(value);
        },
      }
    },
    emits: ['update:pages', 'did-move', 'load-calendar', 'edit-card'],
    mounted() {
      const currentDate = new Date();
      const monthAdjust = parseInt(this.columns) === 3 ? -1 : 0;
      currentDate.setMonth(currentDate.getMonth() + monthAdjust);
      this.$refs.calendar.move(currentDate);
    },
    methods: {
      loadCalendar() {
        this.$emit('load-calendar');
      },
      editCard(id) {
        this.$emit('edit-card', id);
      },
      prev() {
        this.$refs.calendar.moveBy(-1);
      },
      next() {
        this.$refs.calendar.moveBy(1);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-redes-calendar {
    padding: 30px;
    background: $grey-disabled;
    overflow-x: auto;
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $laptop) {
        gap: 10px 8px;
        flex-wrap: wrap;

        :deep(.vc-container) {
          flex: 1 1 100%;
          order: -1;
        }
      }
    }
    &__arrow {
      appearance: none;
      border: none;
      padding: 0;
      min-width: 40px;
      height: 40px;
      background: #007b75;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;
    }
    .next svg {
      transform: rotate(180deg);
    }
  }

  .v-redes-calendar__close-popover {
    all: unset;
    position: absolute;
    top: 8px;
    right: 12px;
    z-index: 99;

    &:hover {
      opacity: 0.8;
    }
  }

  .v-redes-calendar__legend {
    --pane-width: 340px;
    --container-padding: 10px;
    margin-top: 24px;
    margin-inline: auto;
    max-width: #{calc(var(--pane-width) * v-bind(columns) - var(--container-padding) * v-bind(columns))};

    @media (max-width: $tablet) {
      margin-bottom: 24px;
    }

    @media (max-width: $laptop) {
      --pane-width: 100%;
      padding-inline: 26px;
    }
  }

  .v-redes-calendar--mode_single {
    padding: 0;

    &,
    :deep(.vc-pane-header-wrapper),
    :deep(.vc-pane-layout),
    :deep(.vc-title) {
      background-color: transparent;
    }

    :deep(.vc-header) {
      margin-top: 0;
    }

    :deep(.vc-day),
    :deep(.vc-weekday) {
      font: $fira-16-20;
    }

    @media (max-width: $mobile) {
      :deep(.v-redes-calendar__wrapper) {
        flex-wrap: wrap;
        justify-content: center;
      }

      :deep(.vc-container) {
        margin-bottom: 32px;
        flex: 1 1 100%;
      }

      :deep(.v-redes-calendar__arrow) {
        margin-inline: 4px;
        order: 1;
      }
    }
  }
</style>

<style lang="scss">
  .v-redes-calendar {
    // customize
    .vc-popover-content-wrapper {
      max-width: 531px;
      width: 100%;
      padding: 8px 0 1em;
      z-index: 11;

      &:not([data-popper-placement='bottom']) {
        pointer-events: none;
      }

      @at-root .day-popover {
        & + & {
          margin-top: 24px;
        }
      }

      .day-popover {
        padding: 0 12px;

        &__item-body {
          .type,
          .date,
          .time-container {
            margin-bottom: 8px;
          }
          .time-container,
          .date {
            font-size: 12px;
            line-height: 130%;
            color: #d3dfe6;
          }
          .time-block {
            display: flex;
            span {
              &:not(:last-of-type) {
                margin-right: 4px;
              }
            }
          }
          .type {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #d3dfe6;
            width: 90%;
          }
          .about {
            font-size: 14px;
            line-height: 160%;
            color: #ffffff;
            margin-bottom: 24px;
          }
          button {
            width: 100%;
          }
        }
      }

      .vc-popover-content {
        color: #fff;
        background-color: #2d3748;
        border: 1px solid #4a5568;
        border-radius: 0.25rem;
        font-size: 12px;
        font-weight: 500;
        padding: 8px 0;
        margin: 0;
      }

      .vc-nav-popover-container {
        background: $white;
        border: none;
        box-shadow:
          0 4px 10px 0 rgba(128, 144, 160, 0.1),
          0 0 1px 0 rgba(128, 144, 160, 0.81);

        button {
          background: none;
          border: none;
          outline: none;
          font-weight: 400;
          font-size: 13px;
        }

        .vc-popover-caret {
          display: none;
        }

        .vc-nav-header {
          line-height: 40px;

          button {
            border-radius: 0;
            height: 40px;

            &:hover {
              background: $light-grey-7;
            }
          }
        }

        .vc-nav-title {
          font-weight: 700;
          font-size: 18px;
          flex-grow: 1;
        }

        .vc-nav-item {
          width: 100%;

          &.is-active,
          &.is-current {
            box-shadow: none;
            color: $black-st;
          }

          &:hover {
            background: $green-1;
            color: #fff;
          }
        }

        .vc-nav-items {
          justify-items: center;
          grid-column-gap: 10px;
          padding: 0 10px;
        }

        .vc-nav-arrow {
          width: 40px;

          svg {
            stroke: $black-st;
          }
        }
      }
    }

    .vc-arrow {
      display: none !important;
    }
    .vc-bordered {
      border: none;
      border-radius: 0;
    }
    .vc-pane-header-wrapper,
    .vc-pane-layout {
      background: $grey-disabled;
      padding: 0 10px;
    }
    .vc-title-wrapper {
      z-index: 1;
    }
    .vc-week {
      background: $white;
    }
    .vc-day.is-not-in-month * {
      opacity: 1;
      color: #d3dfe6;
    }
    .vc-day,
    .vc-weekday {
      margin: 9px 8px;
    }
    .vc-title {
      background: $grey-disabled;
      span {
        font: $fira-24;
        font-weight: 500;
      }
    }
    .vc-header {
      margin-bottom: 10px;
    }
    .vc-pane {
      min-width: 340px;
      padding: 0 10px;
    }
    .vc-dot {
      background-color: $green;
      &:not(:last-child) {
        display: none;
      }
    }
    .vc-day-content:hover {
      background-color: $green;
      border-radius: 0;
      color: $white;
    }
    .vc-day-content:focus {
      background-color: $green;
      border-radius: 0;
      color: $white;
    }
    .vc-nav-items {
      grid-column-gap: 25px;
    }
    .ps {
      max-width: 1120px;
      max-height: 100%;
      padding: 0 12px;
    }
    .vc-popover-content {
      .ps {
        max-height: 220px;
      }
      .ps--active-y > .ps__rail-y {
        width: 10px;
      }
      .ps__thumb-y {
        width: 6px !important;
      }
    }
    // Сбрасываем внутренний отсуп, чтобы не происходило смещение данных при закрытии попапа
    .ps {
      padding: 0 !important;
    }
  }
  @media (max-width: $mobile) {
    .v-redes-calendar {
      padding: 10px;
    }
  }
</style>
